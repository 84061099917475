import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";
import { get, compact, last } from "lodash";
import PropTypes from "prop-types";
import { useLazyQuery, useQuery } from "@apollo/react-hooks";

import { ContentBlock, TextBlock } from "../../components/Blocks";
import Contents from "../../components/Contents";
import Pages from "../../components/Pages";
import { makeUrl, makeNews, makeArticles, removePreloader, makeVirtualTourSlides } from "../../helpers";
import Breadcrumbs from "../../components/Layout/Breadcrumbs";
import { Share, Weather } from "../../widgets";
import { PureCarousel } from "../../components/PureReactCarousel";
import MapComponent from "../../components/Map";
import Contacts from "../../components/Contacts";
import { MetaImage, MetaPublicAccess, MetaName, MetaTelephone, MetaOpeningHours, MetaAddress } from "../../components/Meta";
import { HERMITAGE_MAX_UPDATE, HERMITAGE } from "../../queries/queries.graphql";
import EmptyData from "../../components/EmptyData";

export const query = graphql`
	query hermitagesDataQuery($slug: String!) {
		hasura {
			...Hermitage
		}
	}
`;

export default function HermitagePage({ location, data, pageContext }) {
	const path = last(compact(location.pathname.split("/")));

	const [hermitages, setHermitages] = useState(get(data, "hasura.hermitages[0]", {}));

	const maxUpdated = new Date(get(data, "hasura.hermitages_aggregate.aggregate.max.updated_at", new Date()));

	const hermitagesOptions = {
		fetchPolicy: "no-cache",
		variables: {
			location: path,
		},
	};

	const { loading: maxUpdateLoading, data: maxUpdateData, error: maxUpdateError } = useQuery(HERMITAGE_MAX_UPDATE, hermitagesOptions);
	const [loadHermitage, { loading: dataLoading, called, data: fetchData, error: dataError }] = useLazyQuery(HERMITAGE, hermitagesOptions);

	useEffect(() => {
		const currentMaxUpdated = new Date(get(maxUpdateData, "hermitages_aggregate.aggregate.max.updated_at", new Date()));

		if (maxUpdateData && !maxUpdateError) {
			if (+currentMaxUpdated !== +maxUpdated) {
				loadHermitage();
			} else {
				removePreloader();
			}
		} else if (maxUpdateError) {
			console.error("Invalid load HermitageMaxUpdate", { maxUpdateError });
			removePreloader();
		}
	}, [maxUpdateLoading]);

	useEffect(() => {
		if (called && fetchData && !dataError) {
			setHermitages(get(fetchData, "hermitages[0]", {}));
		}

		removePreloader(!dataLoading || dataError);
	}, [dataLoading]);

	const {
		main_image,
		title_full,
		main_image_preview,
		page_title_short,
		page_title_full,
		telephone,
		openning_hours,
		address,
		location_object,
	} = hermitages;

	const url = makeUrl.hermitage(hermitages);
	const imageSrc = get(main_image_preview, "src", get(main_image, "src", ""));
	const content_blocks = get(hermitages, "content_blocks", []);
	const news = makeNews(get(hermitages, "hermitages_news", []).map(item => item.news));
	const mediaArticles = makeArticles(get(hermitages, "hermitages_media_articles", []).map(item => item.media_article));
	const weather = get(hermitages, "city.weather", {});
	const virtualTour = makeVirtualTourSlides(get(hermitages, "hermitages_virtual_tours", []));

	return hermitages ? (
		<Pages entity={hermitages} url={url}>
			<div itemScope itemType="https://schema.org/Church">
				<MetaPublicAccess content="true" />
				<MetaImage content={imageSrc} />
				<MetaName content={title_full} />
				<MetaTelephone content={telephone} />
				<MetaOpeningHours content={openning_hours} />
				<MetaAddress content={address} />
				<ContentBlock key={"breadcrumbs"}>
					<div className={"container d-flex justify-content-between"}>
						<Breadcrumbs currentLabel={title_full} pageContext={pageContext} />
						<Weather weather={weather} />
					</div>
				</ContentBlock>
				<ContentBlock
					key={"media-content"}
					mount={content_blocks && !!content_blocks.length}
				>
					<div className={"container"}>
						<Contents items={content_blocks} />
					</div>
				</ContentBlock>
				<ContentBlock key={"news"} mount={news && !!news.length}>
					<div className={"container"}>
						<TextBlock title={"Новости"} list={"Смотреть всё"} listLink={makeUrl.contentNews(pageContext)} allowBtn={news && news.length > 5}>
							<PureCarousel
								type={"SlideNews"}
								items={news}
								mediaSize={["100%", 400]}
								slideRatio={{
									naturalSlideWidth: 3,
									naturalSlideHeight: 4,
								}}
								slideRatioMobile={{
									naturalSlideWidth: 3,
									naturalSlideHeight: 4,
								}}
								params={{
									slidesPerView: 4,
									breakpoints: [
										{
											width: 0,
											slidesPerView: 1,
										},
										{
											width: 768,
											slidesPerView: 2,
										},
										{
											width: 950,
											slidesPerView: 3,
										},
									],
								}}
							/>
						</TextBlock>
					</div>
				</ContentBlock>
				<ContentBlock key={"articles"} mount={mediaArticles && !!mediaArticles.length}>
					<div className={"container"}>
						<TextBlock title={"Статьи"}>
							<PureCarousel
								type={"SlideNews"}
								items={mediaArticles}
								mediaSize={["100%", 400]}
								slideRatio={{
									naturalSlideWidth: 3,
									naturalSlideHeight: 5.5,
								}}
								slideRatioMobile={{
									naturalSlideWidth: 3,
									naturalSlideHeight: 4,
								}}
								params={{
									slidesPerView: 4,
									breakpoints: [
										{
											width: 0,
											slidesPerView: 1,
										},
										{
											width: 576,
											slidesPerView: 2,
										},
										{
											width: 768,
											slidesPerView: 3,
										},
										{
											width: 1136,
											slidesPerView: 4,
										},
									],
								}}
							/>
						</TextBlock>
					</div>
				</ContentBlock>
				<ContentBlock key={"virtual_tours"} mount={virtualTour && !!virtualTour.length}>
					<div className={"container"} >
						<TextBlock title={"Виртуальные экскурсии"} id={"Virtual-Tours"}>
							<PureCarousel
								type={"SlideLinks"}
								items={virtualTour}
								slideRatio={{
									naturalSlideWidth: 2,
									naturalSlideHeight: 1,
								}}
								slideRatioMobile={{
									naturalSlideWidth: 1,
									naturalSlideHeight: 0.8,
								}}
								params={{
									slidesPerView: 2,
									breakpoints: [
										{
											width: 0,
											slidesPerView: 1,
										},
										{
											widh: 576,
											slidesPerView: 2,
										},
										{
											width: 768,
											slidesPerView: 2,
										},
										{
											width: 1136,
											slidesPerView: 2,
										},
									],
								}}
							/>
						</TextBlock>
					</div>
				</ContentBlock>
				<ContentBlock mount={hermitages && address || openning_hours || telephone} key={"contacts"}>
					<div className={"container"}>
						<div className={"row"}>
							<div className={"col-12"}>
								<TextBlock title={"Контакты"} className={"pb-2"}/>
							</div>
						</div>
						<Contacts address={address} hours={openning_hours} telephone={telephone}/>
					</div>
				</ContentBlock>
				<ContentBlock mount={hermitages && location_object} key={"map"}>
					<div className={"container"}>
						<div className={"row"}>
							<div className={"col-12"}>
								<TextBlock title={"Расположение"} />
							</div>
						</div>
					</div>
					<MapComponent items={[hermitages]} showPolygon />
				</ContentBlock>
				<div className={"container"}>
					<Share
						url={url}
						pageTitleShort={page_title_short}
						pageTitleFull={page_title_full}
						imageSrc={imageSrc}
					/>
				</div>
			</div>
		</Pages>
	) : (
		<EmptyData />
	);
}

HermitagePage.propTypes = {
	data: PropTypes.object,
	location: PropTypes.object,
	pageContext: PropTypes.object,
};

HermitagePage.defaultProps = {
	data: {},
	location: {
		pathname: "",
	},
	pageContext: {},
};
